<template>
  <b-row
    class="calculator-page justify-content-center"
    align-v="center"
  >
    <b-col class="mb-6 mr-6">
      <b-card
        header="Fee Calculator"
        class="h6 calculator mb-6"
      >
        <b-row>
          <b-col class="d-inline-flex h9 pl-0">
            <b-col class="text-left">
              Fee proposal
              <i
                v-b-tooltip.hover
                class="ri-question-line"
                title="Total amount the client will see on your proposal"
              />
            </b-col>
            <b-col class="text-right d-inline-flex pr-0">
              <b-form-input
                v-model="feeProposal"
                placeholder="Enter your fee here"
                type="number"
                size="sm"
              />
              <span class="pt-2 pl-1">€</span>
            </b-col>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col class="d-inline-flex h9 pl-0">
            <b-col
              cols="9"
              class="text-left"
            >
              Relancer Service Fee
              <b-btn
                v-b-modal.explanation
                variant="text"
              >
                - Explain this
              </b-btn>
            </b-col>
            <b-col class="text-right pr-0">
              {{ relancerFeeTotal.toFixed(2) }} €
            </b-col>
          </b-col>
          <b-modal
            id="explanation"
            ok-only
            ok-title="Close"
            ok-variant="primary"
            centered
          >
            <b-card
              header="Relancer service fees"
              class="h6"
            >
              <b-row>
                <b-col class="h9 text-left">
                  Relancer's pricing structure is a sliding scale, based on your <b>fee proposal total amount</b>.
                  The higher the proposal, the lower the fee you will pay to Relancer.
                </b-col>
              </b-row>
              <b-row class="mt-4 mb-3">
                <b-col class="h9 text-left">
                  <b>Fee structure</b>
                </b-col>
                <b-col class="h9 text-left">
                  <b>Fee %</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="h9 text-left d-inline-flex p-0">
                  <b-col>First 10,000€</b-col>
                  <b-col>15%</b-col>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col class="h9 text-left d-inline-flex p-0">
                  <b-col>Between 10,000 - 20,000€</b-col>
                  <b-col>10%</b-col>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col class="h9 text-left d-inline-flex p-0">
                  <b-col>After 20,000€</b-col>
                  <b-col>5%</b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-modal>
        </b-row>
        <hr>
        <b-row>
          <b-col class="d-inline-flex h9 pl-0">
            <b-col class="text-left">
              You'll Receive
              <i
                v-b-tooltip.hover
                class="ri-question-line"
                title="The estimated amount you'll receive after service fees"
              />
            </b-col>
            <b-col class="text-right pr-0">
              {{ userReceives }} €
            </b-col>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FeeCalculatorPage',
  data () {
    return {
      feeProposal: 0,
      relancerFee: 15,
      relancerFee2: 10,
      relancerFee3: 5,
    }
  },
  computed: {
    userReceives () {
      let totalPrice = 0
      let gapPrice1 = 0
      let gapPrice2 = 0
      let gapPrice3 = 0
      let gapPrice1Fee = 0
      let gapPrice2Fee = 0
      let gapPrice3Fee = 0

      if (this.feeProposal <= 10000) {
        // Dividing the fee proposal with Relancer's 15% fee
        totalPrice = this.feeProposal - (this.relancerFee * this.feeProposal) / 100
      } else if (this.feeProposal > 10000 && this.feeProposal <= 20000) {
        // Getting the first 5k out of total amount of fee (max 10k)
        gapPrice1 = this.feeProposal - 10000
        // Getting the remainder of the total amount fee - first 5k
        gapPrice2 = this.feeProposal - gapPrice1
        // Getting the first 5k relancer fee 5k / 15%
        gapPrice1Fee = (this.relancerFee2 * gapPrice1) / 100
        // Getting the reminder fee = reminder amount / 10%
        gapPrice2Fee = (this.relancerFee * gapPrice2) / 100
        totalPrice = this.feeProposal - (gapPrice1Fee + gapPrice2Fee)
      } else if (this.feeProposal > 20000) {
        // Getting the reminder if total amount is bigger than 10k
        gapPrice3 = (this.feeProposal - 20000)
        // We already know that if total amount is bigger than 10k then the first relancer fee is 750€
        gapPrice1Fee = 1500
        // Second relancer fee is 500€
        gapPrice2Fee = 1000
        // Getting the last reminder relancer fee = reminder amount / 5%
        gapPrice3Fee = (this.relancerFee3 * gapPrice3) / 100
        totalPrice = this.feeProposal - (gapPrice1Fee + gapPrice2Fee + gapPrice3Fee)
      }
      return totalPrice.toFixed(2)
    },
    relancerFeeTotal () {
      return this.feeProposal - this.userReceives
    },
  },
}
</script>

<style lang="scss" scoped>
  .calculator-page {
    height: 100vh;
    background-color: #f8eeec;

    .calculator {
      width: 500px;
      border-radius: 8px;
      margin: auto;
      height: 250px;

      .card-header {
      text-align: left;
      }

      .form-control {
        text-align: right;
      }

      .currency {
        vertical-align: bottom !important;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
</style>
